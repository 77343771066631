import * as React from "react";
import KnowledgeBaseLayout, {
  LeftBarItem,
} from "../layouts/KnowledgeBaseLayout";
import styled from "styled-components";
import colors from "../layouts/common/colors";
import { graphql, useStaticQuery } from "gatsby";
import { useLanguage } from '../hooks/hooks'

type Issue = {
  id: string;
  descr: string;
  type: string;
};

type Release = {
  name: string;
  timestamp: Date;
  issues: Issue[];
  description: string;
};

const ReleaseContainer = styled.div`
  margin-bottom: 2em;
`;

const ReleaseHeader = styled.div`
  h1 {
    font-size: 1.6em;
    margin-bottom: 0;
  }

  h2 {
    font-size: 1em;
    color: ${colors.blue};
  }
`;

const ReleaseIssue = styled.div`
  display: flex;
  flex-direction: row;

  .id {
    font-size: 1em;
    color: ${colors.blue};
    width: 80px;
  }

  .type {
    color: ${colors.green};
    width: 80px;
  }
`;

// @ts-ignore
const ReleaseNotes: React.FunctionComponent = ({
  pageContext: { youTrackData },
}) => {
  const language = useLanguage()

  const allReleaseData = useStaticQuery(graphql`
    query ReleaseNotes {
      releaseNotesJson {
        en {
          description
          version
        }
        nl {
          description
          version
        }
      }
    }
  `)

  const localizedData = allReleaseData.releaseNotesJson[language] || allReleaseData.releaseNotesJson["en"]
  const releaseData = localizedData.reduce((acc: any, note: any) => {
    acc[note.version] = note.description;
    return acc;
  }, {});

  const releases: Release[] = [];
  youTrackData.forEach((entry: any) => {
    const issue: Issue = {
      id: `${entry.project.shortName}-${entry.numberInProject}`,
      descr: entry.summary,
      type: "",
    };

    entry.customFields.forEach((field: any) => {
      if (field.name === "Fixed in build") {
        let release: Release | undefined = releases.find(
          (other) => field.value != null && other.name == field.value.name
        );
        if (!release) {
          if (!field.value || !field.value.name) {
            if (entry.project.shortName && entry.numberInProject) {
              console.log(`Fixed in Build must be filled for ${entry.project.shortName}-${entry.numberInProject} for it to be visible in Release Notes!`)
            }
            //console.log(entry, field);
          } else {
            release = {
              name: field.value.name,
              timestamp: new Date(field.value.assembleDate),
              issues: [],
              description: releaseData[field.value.name]
                ? releaseData[field.value.name]
                : "",
            };
            releases.push(release);
          }
        }

        if (release) {
          release.issues.push(issue);
        }
      }

      if (field.name === "Type") {
        issue.type = field.value.name;
      }
    });
  });

  releases.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

  const leftBarItems: LeftBarItem[] = releases.map((release) => {
    return {
      title: release.name.replace('Hotfix ', ''),
      url: `#${release.timestamp.getTime().toString()}`,
      level: 0,
      isInternal: true,
    };
  });

  return (
    <KnowledgeBaseLayout leftBarItems={leftBarItems}>
      <main>
        {releases.map((release) => (
          <ReleaseContainer key={release.name}>
            <ReleaseHeader>
              <a id={release.timestamp.getTime().toString()} />
              <h1>{release.name.replace('Hotfix ', '')}</h1>
              <h2>{release.timestamp.toDateString()}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: release.description }}
              ></div>
            </ReleaseHeader>
            {release.issues.map((issue) => (
              <ReleaseIssue key={issue.id}>
                <div className="id">{issue.id}</div>
                <div className="descr">{issue.descr}</div>
              </ReleaseIssue>
            ))}
          </ReleaseContainer>
        ))}
      </main>
    </KnowledgeBaseLayout>
  );
};

export default ReleaseNotes;
